import React, { useState } from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import Slider from '~components/Slider'
import { RoundedImage, ImageSlider, ImageCaption, ImageSlide } from '~styles/global'
import { css } from '@emotion/react'
import Button from '~components/Button'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link to={resolveLink(props.mark.document)}>{props.children}</Link> 
			} else {
				return <a href={props.mark?.url} target={props.mark.blank ? '_blank' : '_self'} rel='noreferrer'>{props.children}</a>
			}
		}
	},
	types: {
		block: props => {
			const { style = 'normal' } = props.node
			if (props.children[0] === '') {
				return <br/>
			}			
			if (style === 'h2') {
				return (
					<h5 css={css`margin-bottom: var(--m);`}>
						{props.children}
					</h5>	
				)
				// Fall back to default handling
			}
			if (style === 'normal') {
				return (
					<p css={css`margin-bottom: var(--l);`}>
						{props.children}
					</p>	
				)
				// Fall back to default handling
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
		imageSlider: props => {
			const [currentSlide, setCurrentSlide] = useState(0)
			return (
				<ImageSlider>
					<Slider onChange={slideNumber => setCurrentSlide(slideNumber)}>
						{props?.node?.slides?.map(slide => (
							<ImageSlide key={slide._key} image={slide} aspectRatio={1.46}/>
						))}
					</Slider>
					<div>
						<BlockContent blocks={props?.node?.slides[currentSlide]?.caption}/>
					</div>
				</ImageSlider>
			)
		},
		button: props => {
			return (
				<div css={css`margin-bottom: var(--m);`}>
					<Button color={props?.node?.color} link={props?.node?.link} css={css`margin-bottom: var(--m);`}>
						{props?.node?.text}
					</Button>
				</div>
			)
		},
		imageWithCaption: props => {
			return (
				<div css={css`margin-bottom: var(--l);`}>
					<RoundedImage image={props?.node}/>
					<ImageCaption><BlockContent blocks={props?.node?.caption}/></ImageCaption>
				</div>
			)
		},
		download: props => {
			return (
				<div css={css`margin-bottom: var(--m);`}>
					<Button 
						link={{ 
							linkType: 'external', 
							url: props?.node?.file?.asset?.url
						}} 
						download={true}
						color='blue'
					>
						{props?.node?.name}
					</Button>
				</div>
			)
		}
	}
}

const RichText = ({ content }) => <BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>

RichText.propTypes = {
	content: PropTypes.array,
}

export default RichText
